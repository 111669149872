.welcome-container {
    position: relative;
    z-index: 1;
    background: #FFFFFF;
    max-width: 768px;
    margin: 0 auto 100px;
    padding: 4% 0 0;
    text-align: center;
}

.welcome-container--welcome {
    font-size: 54px;
}

.welcome-container--school-name {
    font-size: 24px;
    display: block;
    padding: 20px 0 0 0;
}

.welcome-container--app-name {
    display: block;
    font-size: 24px;
    padding: 20px 0 0 0;
}

.login-form__input-text {
    outline: 0;
    background: #f2f2f2;
    width: 60%;
    border: 0;
    margin: 0 0 15px;
    padding: 15px;
    box-sizing: border-box;
    font-size: 14px;
}
/**************/
/* Sign in */
/**************/
.signin-container {
    position: relative;
    z-index: 1;
    background: #FFFFFF;
    max-width: 768px;
    margin: 0 auto 100px;
    text-align: center;
}

.signin--button {
    text-transform: uppercase;
    outline: 0;
    background: #4CAF50;
    width: 20%;
    border: 0;
    padding: 13px;
    color: #FFFFFF;
    font-size: 14px;
    cursor: pointer;
    display: block;
    border-radius: 16px;
    margin: 0 auto;
    -webkit-transition: all 0.3 ease;
    transition: all 0.3 ease;
}

.signin--button--highlight:hover, .login-form__button--highlight:active, .login-form__button--highlight:focus {
    background: #43A047;
}

.signin-signup-container {
    padding: 40px 0 0 0;
}
/**************/
/* Sign up */
/**************/
.signup-container {
    position: relative;
    z-index: 1;
    background: #FFFFFF;
    max-width: 768px;
    margin: 0 auto 100px;
    text-align: center;
    display: none;
    padding: 4% 0 0;
}

.signup-container--title {
    font-size: 24px;
    display: block;
    padding: 40px 0 40px 0;
}

.signup--button {
    text-transform: uppercase;
    outline: 0;
    background: #4CAF50;
    width: 20%;
    border: 0;
    padding: 13px;
    color: #FFFFFF;
    font-size: 14px;
    cursor: pointer;
    display: block;
    border-radius: 16px;
    margin: 0 auto;
    -webkit-transition: all 0.3 ease;
    transition: all 0.3 ease;
}

.signup--button--highlight:hover, .login-form__button--highlight:active, .login-form__button--highlight:focus {
    background: #43A047;
}

.signin-signup--dont-have-account {
}

.signin-signup--signup {
    padding: 0 0 0 15px;
}

.signup-signin-container {
    padding: 40px 0 0 0
}

.signup-signin--already-have-account {
}

.signup-signin--signin {
    padding: 0 0 0 15px;
}

.need-access-key-container {
    padding: 200px 0 0 0;
}

.need-to-contact-school_container {
    padding: 30px 0 0 0;
}

.need-to-contact-school {
    margin: 0 0 30px 0;
    text-align: center;
    font-weight: bold;
}

select:required:invalid {
    color: gray;
}

option[value=""][disabled] {
    display: none;
}

option {
    color: black;
}

.forgot-link-container {
    margin: 30px 0 0 0;
}

.forgot-link {
    font-size: 15px;
    color: #797d85;
    text-decoration: none;
}

.login-form__message {
    color: #FF0000;
    display: block;
    margin: 20px 0 0;
}

@media only screen and (max-width: 1366px) {
    .signup-container {
        padding: 0;
    }
}