html {
    height: 100%;
    min-height: 100%;
}

body {
    margin: 0;
    font-family: "wf_segoe-ui_semilight", "Segoe UI Semilight", "Segoe WP Semilight", "Segoe UI", "Segoe WP", Tahoma, Arial, sans-serif;
    height: 100%;
    min-height: 100%;
}

.action-link {
    margin: 0 0 10px 0;
    cursor: pointer;
    color: blue;
    text-decoration: underline;
}

.main-list-container {
    padding: 25px 20px 20px 15px;
}

.main-list-layout {
    font-size: 14px;
}

.main-list__table {
    background-color: #f4f8f9;
    border-collapse: separate;
    border-spacing: 0 6px;
    width: 100%;
}

.main-list__table-col-header {
    text-align: left;
    padding: 0px 12px;
}

.main-list__table-col-header--center {
    text-align: center;
}

.main-list__table-row {
    background-color: #ffffff;
}

.main-list-header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
    align-items: center;
}

.main-list-header__title {
    font-size: 29px;
    font-weight: 450;
    margin: 27px;
}

.table-child {
    border-collapse: collapse;
    width: 600px;
}

.table-child-header {
    padding: 8px;
    text-align: left;
    border-bottom: 1px solid #ddd;
}

.table-child-header--right {
    text-align: right;
}

.table-child-header--center {
    text-align: center;
}

.table-child-data {
    padding: 8px;
    text-align: left;
    border-bottom: 1px solid #ddd;
}

.table-child-data--right {
    text-align: right;
}

.table-child-data--center {
    text-align: center;
}

.table-child-action-link {
    margin: 0 0 10px 0;
    cursor: pointer;
    color: blue;
    text-decoration: underline;
}

.note {
    font-size: 13px;
    font-style: italic;
}

.main-form-layout {
    display: grid;
    font-size: 14px;
}

.main-form-container-section {
    margin: 20px 0 0 20px;
}

.main-form-container-section__header {
    font-size: 18px;
    display: inline-block;
    margin: 0 0 15px 0;
}

.field-container {
    margin: 0 0 10px 0;
}

.field-container__label {
    display: inline-block;
    height: 22px;
    text-align: left;
    width: 130px;
    margin: 0 10px 0 0;
}

.field-container__label--long {
    display: inline-block;
    height: 22px;
    text-align: left;
    width: 180px;
    margin: 0 10px 0 0;
}

.field-container__label-check-radio {
    display: inline-block;
    height: 22px;
    text-align: left;
    min-width: 80px;
    max-width: 180px;
    margin: 0 10px 0 0;
}

.field-container__input-text {
    width: 280px;
    height: 22px;
}

.field-container__input-select {
    width: 280px;
    height: 28px;
}

.field-container__input-text--long {
    width: 380px;
}

.field-container__input-text--short {
    width: 120px;
}

.field-container__input-select--long {
    width: 384px;
}

.field-container__input-amount {
    text-align: right;
}

.checkbox-field-container {
    margin: 30px 0 0 0;
}

.main_btn {
    border-radius: 5px;
    padding: 12px 30px;
    font-size: 14px;
    text-decoration: none;
    text-align: center;
    margin: 0 20px;
    color: #fff;
    position: relative;
    display: inline-block;
    cursor: pointer;
    background-color: #55acee;
    min-width: 55px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.main_btn:hover {
    background-color: #6fc6ff;
}

.main_btn:active {
    background-color: #55acee;
}

.main_btn--disabled {
    background-color: #bdc4c9;
    pointer-events: none;
}

.modal-overlay {
    position: fixed;
    z-index: 1;
    padding-top: 100px;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgb(0, 0, 0); /* Fallback color */
    background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
}

.modal-form {
    background-color: #fefefe;
    margin: auto;
    padding: 20px;
    border: 1px solid #888;
    width: 80%;
    overflow: hidden;
}

.modal-form-main-container {
    width: 35%;
    min-width: 600px;
}

.modal-form-container-section {
    margin: 20px 0 0 30px;
}

.modal-form__title {
    background: white;
    padding: 0 8px 8px 8px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}

.modal-form__btn-container {
    display: flex;
    justify-content: flex-end;
    margin: 40px 0 0 0;
}

.modal-form__cancel-btn {
    background-color: #4f98d0;
    color: white;
    padding: 14px 25px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    min-width: 60px;
    margin-left: 20px;
    cursor: pointer;
}

.modal-form__cancel-btn:hover {
    background-color: #77acd5;
}

.modal-form__cancel-btn:active {
    background-color: #4f98d0;
}

.modal-form__ok-btn {
    background-color: #4caf50;
    color: white;
    padding: 14px 25px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    min-width: 60px;
    cursor: pointer;
}

.modal-form__ok-btn:hover {
    background-color: #5cce61;
}

.modal-form__ok-btn:active {
    background-color: #4caf50;
}

.modal-form__ok-btn--neutral {
    background-color: #55acee;
    color: white;
    padding: 14px 25px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    min-width: 60px;
    cursor: pointer;
}

.modal-form__ok-btn--neutral:hover {
    background-color: #6fc6ff;
}

.modal-form__ok-btn--neutral:active {
    background-color: #55acee;
}

.main-list__active-status {
    --borderWidth: 4px;
    --height: 15px;
    --width: 6px;
    --borderColor: #47d247;
    display: inline-block;
    transform: rotate(45deg);
    height: var(--height);
    width: var(--width);
    border-bottom: var(--borderWidth) solid var(--borderColor);
    border-right: var(--borderWidth) solid var(--borderColor);
}

.top-bar__greeting {
    font-size: 18px;
    font-weight: bold;
}

.content {
    margin-left: 280px; /* Same as the width of the sidebar */
    padding: 0px 0px;
    height: 100%;
}

@media only screen and (max-width: 1366px) {
    .content {
        margin-left: 180px; /* Same as the width of the sidebar */
        padding: 0px 0px;
    }
}